
import React, { useEffect } from 'react';
import formClose from "./images/close_48dp_FILL0_wght400_GRAD0_opsz48.png";

function ZohoWebForm({ isOpen, toggle }) {
    useEffect(() => {
        function wfa_pstMesgFrmFom( evt ){
            if( evt.origin === 'https://crm.zoho.com' || evt.origin === 'https://crm.zohopublic.com' ){
                var loc_obj = JSON.stringify({
                    origin: window.location.origin, pathname: window.location.pathname, search: window.location.search, hash: window.location.hash
                });
                evt.source.postMessage( ( 'prnt_wnd_pg_lc_rc_frm_prwindow_' + loc_obj ), evt.origin );
            }
        }
        window.addEventListener( 'message', wfa_pstMesgFrmFom, false );

        // Cleanup function
        return () => {
            window.removeEventListener('message', wfa_pstMesgFrmFom);
        };
    }, []);

    return (
        <div className='form'>
            {isOpen && (
                <div className="form__container">
                    <div className="form__button-container">
                        <img src={formClose} alt="" className="form__button" onClick={toggle} />
                    </div>
                    <div className="iframe__container">
    <iframe 
        src='https://crm.zoho.com/crm/WebFormServeServlet?rid=53f5f6d6f14961d3b8a736f61f5e7ee04bb86d024e51622697fe42b81522cc48ac4c80c84b2663fa5305f236369e4edbgida158f1917015e790c16715ed7e8ca1a43aa15b2d434273d41231c8396068b5bf'
        title='Zoho CRM Web Form'
    >
    </iframe>
</div>

                </div>
            )}
        </div>
    );
}

export default ZohoWebForm;
