import React from "react";
import food from "./images/food_.jpg";
import food2 from "./images/food2.jpg"



function Food (){
    return(

        <div className="food reveal" id="food">
            <div className="food__container">
                <div className="food__main">
                    <div className="food__text-container">
                        <h4 className="food__title">SAN ANTONIO'S CULINARY CROWN JEWEL: A TASTE OF TEXAS EXCELLENCE</h4>
                        <p className="food__text">Each bite is crafted to be an unforgettable experience. Explore our upscale restaurant's menu featuring tantalizing tasting boards or savor the bold flavors of our New York Strip, perfectly complemented by our spectacular à la carte side dishes. </p>
                    </div>
                    <img src={food} alt="" className="food__main-picture" />
                </div>

                <div className="food__main">
                <img src={food2} alt="" className="food__main-picture" />
                    <div className="food__text-container">
                        <h4 className="food__title">THE PERFECT COMPLEMENT TO YOUR EVENING</h4>
                        <p className="food__text">Elevate your poker experience with our exceptional specialty drinks, the perfect companion at any of our poker tables. 
                        Delight your palate with any of our expertly-crafted cocktails or timeless classic beverages, whether you prefer them on the rocks or without. </p>
                    </div>
                 
                </div>

            </div>




        </div>
    )
}

export default Food