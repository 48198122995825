import React, { useState } from "react";
import firstWinner from "./images/firstWinner.jpg"
import secondWinner from "./images/secondWinner.jpg";
import thirdWinner from "./images/thirdWinner.jpg"
import ZohoWebForm from "./ZohoWebForm";


function Winners () {

    const [formOpen, setFormOpen] =useState(false);


    function openForm (){
        setFormOpen(true)
    }
    

    return (
        <div className="winners reveal">
            <div className="winners__grid">
                <div className="winners__grid-main">
                    <img src={firstWinner} alt="" className="winners__grid-main-picture" />

                </div>
                <div className="winners__text-container">
                    <h4 className="winners__title">DAILY GAMING THRILLS AT DIAMANTE, BROOKLYN SQUARE: WHERE VICTORY HAPPENS EVERY DAY</h4>
                    <p className="winners__text">Our daily tournaments offer a battleground for skilled players seeking fame and fortune, with tantalizing prizes awaiting the boldest competitors..</p>
                    <div className="winners__button-container">
                    <button onClick={openForm} className="winners__button">Are you new?</button>
                    </div>
                    

                </div>
                <div className="winners__grid-secondary">
                    <img src={secondWinner} alt="" className="winners__grid-secondary-picture" />
                    <img src={thirdWinner} alt="" className="winners__grid-secondary-picture" />

                </div>
            </div>
          
                <ZohoWebForm isOpen={formOpen} toggle={() => setFormOpen(false)}></ZohoWebForm>
         
        </div>
    );
}

export default Winners;
