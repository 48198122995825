import React from "react";
import vertical from "./images/VERTICAL TEXAS 2-2.gif";
import horizontal from "./images/LOGO TEXAS _HORIZONTAL_ 2 dado-4.gif";

function MainBanner() {
    return (
        <div className="mainBanner">
            <div className="main__banner-container">
                <img
                    src={window.innerWidth < 768 ? vertical : horizontal}
                    alt="Main banner"
                    className="mainImage"
                />
            </div>
        </div>
    );
}

export default MainBanner;
