import React, { useState } from "react";
import footerLogo from "./images/SA_LOGO_FINAL_blanco.png"
import facebook from "./images/Logos redes-02.png"
import instagram from "./images/Logos redes_Mesa de trabajo 1.png"
import PrivacyPolicy from "./PrivacyPolicy";



function Footer () {

    const [isModalOpen, setModalOpen] = useState(false);

    // La sintaxis correcta para la declaración de funciones es sin el signo de igual
    function openModal() {
        setModalOpen(true);
    }
    

    return (
        <div className="footer ">
            <img src={footerLogo} alt="" className="footer__logo" />
            <p className="footer__address">1126 N ST.MARY'S STREET, SAN ANTONIO TX 78215</p>
            <p className="footer__phone">726 241-4589</p>
            <div className="footer__media-container">
                <div className="footer__media-title-container">
                    <h4 className="footer__media-title">FOLLOW US</h4>
                </div>
                <div className="footer__media-picture-container">
                <a className="footer__media-attribute" href="https://www.facebook.com/diamanteatbrooklynsquare" target="_blank" rel="noopener noreferrer">
                        <img src={facebook} alt="Facebook" className="footer__media" />
                    </a>
                    <a className="footer__media-attribute2"  href="https://www.instagram.com/diamanteatbrooklynsquare/" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="Instagram" className="footer__media" />
                    </a>
                </div>
              

            </div>
            <ul className="footer__documents">
                <li onClick={openModal} className="footer__document">Privacy policy</li>

            </ul>
            <PrivacyPolicy isOpen={isModalOpen} toggleModal={() => setModalOpen(false)} ></PrivacyPolicy>



        </div>
    );
}

export default Footer;
