import React from "react";
import { useState, useEffect } from 'react';
import model1 from "./images/welcomePicture1.png";
import model2 from "./images/welcomePicture10.png";
import ZohoWebForm from "./ZohoWebForm";


// import secondImage from "./images/secondImage.jpg"; // Import your second image here if needed

function Welcome() {
    const [src, setSrc] = useState(model1);
    const images = [model1, model2];
    const [formOpen, setFormOpen] = useState(false);

    function openForm(){
        setFormOpen(true)
    }

    useEffect (() =>{
        const interval = setInterval(() => {
            setSrc(prevSrc =>{
                const currentIndex = images.indexOf(prevSrc);
                const nextIndex =(currentIndex + 1) % images.length;
                const picture = document.querySelector(".welcome__image");
                picture.style.transition = "opacity 0.5s ease-in-out"; // Agregar transición de opacidad
                picture.style.opacity = "0"; // Establecer opacidad a 0 antes de cambiar la imagen
                setTimeout(() => {
                    picture.style.opacity = "1"; // Restaurar la opacidad a 1 después de 0.5 segundos
                    setSrc(images[nextIndex]);
                }, 500); // Esperar 500 milisegundos antes de cambiar la imagen
                return prevSrc; // Devolver prevSrc para evitar errores en la actualización del estado
            })
        }, 5000);
        return () => clearInterval(interval);
    }, );

    return (
        <div className="welcome reveal">
            <div className="welcome__grid-container">
                <div className="welcome__text-container">
                    <h1 className="welcome__text-title">LUXURIOUS EXPERIENCE IN SAN ANTONIO.</h1>
                    <p className="welcome__text"> our prestigious establishment offers an unparalleled experience that is sure to leave you captivated, Join us at our esteemed club in San Antonio, 
                    where every moment promises an experience that is as lavish as it is exhilarating.</p>
                    <div className="welcome__button-container">
                        <button onClick={openForm} className="welcome__button">Are you new?</button>
                    </div>
                </div>
                
                <div className="welcome__image-container">
                    <img className="welcome__image" src={src} alt="Welcome Picture 1" />
                  
                </div>
            </div>
            <ZohoWebForm isOpen ={formOpen} toggle={() => setFormOpen(false)}></ZohoWebForm>
        </div>
    );
}

export default Welcome;
