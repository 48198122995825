import React from "react";
import closeButton from "./images/close_48dp_FILL0_wght400_GRAD0_opsz48.png";

function PrivacyPolicy({isOpen, toggleModal}) {
  return (
    <>
    {isOpen && (
      <div className="privacyPolicy no-reveal" >
        <div className="privacyPolicy__button-container">
          <img src={closeButton} alt="" className="privacyPolicy__button" onClick={toggleModal} />
        </div>

        <div className="privacyPolicy__content">
          <div className="privacyPolicy__title-container">
            <h4 className="privacyPolicy__title">PRIVACY POLICY</h4>
          </div>
          <div className="privacyPolicy__text-container">
            <h3 className="privacyPolicy__tex-title">Introduction</h3>
            <p className="privacyPolicy__text">
              Diamante at brooklyn square ("we", "our", "us") is committed to protecting the
              privacy and security of your personal information. This privacy
              policy explains how we collect, use, share, and protect your
              personal information.
            </p>
          </div>

          <div className="privacyPolicy__text-container">
            <h3 className="privacyPolicy__tex-title">
              What Personal Information We Collect
            </h3>
            <p className="privacyPolicy__text">
              We may collect the following types of personal information:
            </p>
          </div>
          <ul className="privacyPolicy__list-container">
            <li className="privacyPolicy__list">
              Your name, address, email address, and telephone number.
            </li>
            <li className="privacyPolicy__list">
              Account information, such as usernames and passwords.
            </li>
            <li className="privacyPolicy__list">
              Billing and payment information;
            </li>
            <li className="privacyPolicy__list">
              Date of birth and gender (if provided);
            </li>
            <li className="privacyPolicy__list">
              Purchase history and saved items;
            </li>
            <li className="privacyPolicy__list">
              Information from credit checks or financial assessments;
            </li>
            <li className="privacyPolicy__list">
              Usage data for our website and systems;
            </li>
            <li className="privacyPolicy__list">
              Responses to surveys, competitions, and promotions.
            </li>
          </ul>

          <div className="privacyPolicy__text-container">
            <p className="privacyPolicy__text">
              Providing your personal information is voluntary, but it may be
              necessary for us to provide you with our services. Without it, our
              services may be limited.
            </p>
          </div>
          <div className="privacyPolicy__text-container">
            <h3 className="privacyPolicy__tex-title">
              How We Use Your Personal Informationt
            </h3>
            <p className="privacyPolicy__text">
              We use your personal information for the following purposes:
            </p>
          </div>
          <ul className="privacyPolicy__list-container">
          <li className="privacyPolicy__list">
              To fulfill our contractual obligations to you.
            </li>
            <li className="privacyPolicy__list">
            Message frequency varies per user.
            </li>
            <li className="privacyPolicy__list">
            Message and data rates may apply.
            </li>
            <li className="privacyPolicy__list">
            Text HELP for help. Text STOP to unsubscribe
            </li>
            <li className="privacyPolicy__list">
            Carriers are not liable for delayed or undelivered messages.
            </li>
            <li className="privacyPolicy__list">
              To comply with legal and regulatory requirements;
            </li>
            <li className="privacyPolicy__list">
              For our legitimate business interests, including improving our
              services and offerings;
            </li>
            <li className="privacyPolicy__list">
              With your consent, for specific purposes such as marketing.
            </li>
            <li className="privacyPolicy__list">
              We do not sell or lease your personal information to third
              parties.
            </li>
          </ul>
          <div className="privacyPolicy__text-container">
            <h3 className="privacyPolicy__tex-title">
              How We Collect Your Personal Information We collect personal
              information:
            </h3>
            <p className="privacyPolicy__text">
              We collect personal information:
            </p>
          </div>

          <ul className="privacyPolicy__list-container">
            <li className="privacyPolicy__list">
              Directly from you, through our website and apps.
            </li>
            <li className="privacyPolicy__list">
              From publicly accessible sources.
            </li>
            <li className="privacyPolicy__list">
              With your consent from third parties.
            </li>
            <li className="privacyPolicy__list">
              Through cookies and similar technologies on our website.
            </li>
          </ul>
          <div className="privacyPolicy__text-container">
            <h3 className="privacyPolicy__tex-title">
              Cookies and Tracking Technologies
            </h3>
            <p className="privacyPolicy__text">
              We use cookies to enhance your experience on our website, such as
              by saving your preferences. You can control cookies through your
              browser settings, though some website features may not function
              correctly without them.
            </p>
          </div>

          <div className="privacyPolicy__text-container">
            <h3 className="privacyPolicy__tex-title">
              Sharing Your Personal Information
            </h3>
            <p className="privacyPolicy__text">
              We may share your personal information with:
            </p>
            <p className="privacyPolicy__text">
            We do not share your opt-in information with any third parties. Your privacy is important to us, and we ensure that your data is kept secure and confidential.
            </p>
            <p className="privacyPolicy__text">
            No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.
            </p>
          </div>

          <ul className="privacyPolicy__list-container">
            <li className="privacyPolicy__list">
              Service providers assisting with our business activities;
            </li>
            <li className="privacyPolicy__list">
              Third parties with your consent;
            </li>
            <li className="privacyPolicy__list">
              Legal and regulatory authorities, as required.
            </li>
          </ul>
          <div className="privacyPolicy__text-container">
            <p className="privacyPolicy__text">
              We ensure that any third parties accessing your personal
              information are subject to confidentiality obligations.
            </p>
            <div className="privacyPolicy__text-container">
              <h3 className="privacyPolicy__tex-title">
                International Transfers
              </h3>
              <p className="privacyPolicy__text">
                Your personal information may be processed outside the European
                Economic Area (EEA). We take steps to ensure that your
                information is protected according to applicable data protection
                laws.
              </p>
            </div>

            <div className="privacyPolicy__text-container">
              <h3 className="privacyPolicy__tex-title">Your Rights</h3>
              <p className="privacyPolicy__text">
                You have rights regarding your personal information, including
                accessing, correcting, and deleting your data, subject to
                certain exceptions. To exercise these rights, please contact us.
              </p>
            </div>

            <div className="privacyPolicy__text-container">
              <h3 className="privacyPolicy__tex-title">Contact Us</h3>
              <p className="privacyPolicy__text">
                If you have any questions about this privacy policy or our
                privacy practices, please contact us at Diamante at brooklyn square
              </p>
            </div>

            <div className="privacyPolicy__text-container">
              <h3 className="privacyPolicy__tex-title">Disclaimer</h3>
              <p className="privacyPolicy__text">
                In compliance with the FTC guidelines regarding the use of
                endorsements and testimonials in advertising, we wish to inform
                you of the following: Testimonials featured on this website are
                actual statements made by our customers via text, audio, or
                video submission. They represent real-life experiences of
                individuals who have used our products and/or services in
                various ways. However, it's important to understand that these
                results are individual experiences, and they vary from person to
                person. We do not claim these results to be typical for all
                consumers. The testimonials are not necessarily indicative of
                all those who will use our products and/or services. The
                testimonials presented on our site are provided exactly as we
                received them except for the correction of grammatical or typing
                errors. Some testimonials may have been condensed, meaning not
                the entire message received from the testimonial writer is
                displayed if it seemed lengthy or not entirely relevant to the
                broader audience. Diamante at brooklyn square is not responsible for any of
                the opinions or comments posted on our site. Diamante at brooklyn square is
                not a forum for testimonials; however, we provide testimonials
                as a means for customers to share their experiences with one
                another. To protect against abuse, all testimonials appear after
                they have been reviewed by the management of Diamante at brooklyn square.
                Diamante at brooklyn square does not necessarily share the opinions, views,
                or commentary of any testimonials on this website, which are
                strictly the views of the reviewer. This disclaimer was last
                updated on February 2024.
              </p>
            </div>
          </div>
        </div>
      </div>
    )}
      
    </>
  );
}

export default PrivacyPolicy;
