import React from "react";
import headerLogo from "./images/SA_LOGO_FINAL_negro (1)_Mesa de trabajo 1.png"



function AppHeader (){


    return(

<div className="header">
    <div className="header__logo-container">
        <img className="header__logo" src={headerLogo} alt="" />
    </div>
    <ul className="header__list-container">
        <li className="header__list">
            <a className="header__attribute" href="#promotions">PROMOTIONS</a>
        </li>
        <li className="header__list">
            <a className="header__attribute" href="#food">EAT & DRINK</a>
        </li>
        <li className="header__list">
            <a className="header__attribute" href="#tournaments">TOURNAMENTS</a>
        </li>
    
    </ul>
</div>

    )
}

export default AppHeader