import React, { useState } from "react";
import promotions1 from "./images/promotions1.jpg";
import promotions2 from "./images/promotions2.jpg";
import promotions3 from "./images/promotions3.jpg";
import promotions4 from "./images/promotions4.jpg";
import Modal from "./Modal";

function Promotions () {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(""); // Estado para almacenar la URL de la imagen seleccionada

    const promotionsImages = [
        { id: 1, image: promotions1 },
        { id: 2, image: promotions2 },
        { id: 3, image: promotions3 },
        { id: 4, image: promotions4 },
        { id: 5, image: promotions1 },
        { id: 6, image: promotions2 },
        { id: 7, image: promotions3 },
        { id: 8, image: promotions4 }
    ];

    const openModal = (imageUrl) => {
        setSelectedImage(imageUrl); // Almacena la URL de la imagen seleccionada en el estado
        setIsModalOpen(true);
    };

    return (
        <div className="promotions reveal" id="promotions">
            <div className="promotions__text">
                <h2 className="promotions__title">PROMOTIONS</h2>
                <h3 className="promotions__subtitle">Don't miss out on our special offers and exciting events!</h3>
            </div>

            <div className="promotions__pictures-container">
                <div className="promotions__scroller-container">
                    <div className="promotions__pictures">
                        {promotionsImages.map(picture => (
                            <img
                                key={picture.id}
                                className='promotions__image'
                                src={picture.image}
                                alt=""
                                onClick={() => openModal(picture.image)} // Llama a la función openModal con la URL de la imagen
                            />
                        ))}
                    </div>
                </div>
            </div>

            {/* Renderiza el Modal con la URL de la imagen seleccionada */}
            <Modal isOpen={isModalOpen} toggleModal={() => setIsModalOpen(false)} imageUrl={selectedImage} />
        </div>
    );
}

export default Promotions;
