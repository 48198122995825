import React  from "react";
import pokerVertical from "./images/pokerVertical.mp4";
import pokerHorizontal from "./images/POKER JAVI HORIZONTAL .mp4";

function Video() {


    return (
        <div className="video-master reveal">
            <div className="video">
                <div className="video__text-container">
                    <h4 className="video__title">OUR CLUB</h4>
                    <p className="video__subtitle">In this oasis of luxury and competition, time seems to stand still, leaving room only for the intensity of the game and the camaraderie among enthusiasts.</p>
                </div>
                <video className="video__container" controls>
                    <source   src={window.innerWidth <768 ? pokerVertical : pokerHorizontal} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
              
            </div>
            
        </div>
    );
}

export default Video;
