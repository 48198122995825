import React from "react";

import closeModal from "./images/close.png"












function Modal ({isOpen,toggleModal, imageUrl}) {
    return(
        <>
        {isOpen &&(

<div className="modal">
<div className="modal__container">
    <div className="modal__button-container">
        <img src={closeModal} alt="" className="modal__button" onClick={toggleModal} />
    </div>
    <div className="modal__picture-container">
        <img src={imageUrl} alt="" className="modal__picture" />

    </div>
</div>



</div>

        )}
        </>

    );
}

export default Modal