import React, { useState, useEffect } from 'react';
import AppHeader from './Header.jsx';
import MainBanner from './MainBanner.jsx';
import Welcome from './Welcome.jsx';
import Promotions from './Promotions.jsx';
import Tournaments from './Tournaments.jsx';
import Scroller from './Scroller.jsx';
import Video from './Video.jsx';
import Winners from './Winners.jsx';
import Food from './Food.jsx';
import Footer from './Footer.jsx';
import PrivacyPolicy from './PrivacyPolicy.jsx';
import Chat from './Chat.jsx';
import { reveal } from './Reveal.js';
import ClipLoader from 'react-spinners/ClipLoader';

import './App.css';

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', reveal);

    // Ejecutar reveal una vez para elementos visibles al cargar la página
    reveal();

    return () => {
      window.removeEventListener('scroll', reveal);
    };
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="loader-container">
          <ClipLoader color={"#E20d13"} loading={loading} size={100} />
        </div>
      ) : (
        <>
          <AppHeader />
          <MainBanner />
          <Scroller />
          <Welcome />
          <Promotions />
          <Tournaments />
          <Video />
          <Winners />
          <Food />
          <Footer />
          <PrivacyPolicy />
          <Chat />
        </>
      )}
    </div>
  );
}

export default App;
