import React, { useEffect } from "react";

function Scroller() {
    useEffect(() => {
        const scrollers = document.querySelector(".scroller");

        function addCloneContentToAnimation() {
            const scrollerInner = scrollers.querySelector(".scroller-inner");
            const scrollerContent = Array.from(scrollerInner.children);
            scrollerContent.forEach((item) => {
                const duplicateItem = item.cloneNode(true);
                scrollerInner.appendChild(duplicateItem);
            });
        }

        addCloneContentToAnimation();
    }, []);

    return (
        <div className="scroller reveal">
            <ul className="tag-list scroller-inner">
                <li>CASH PROMOTIONS</li>
                <li>DAILY PROMOTIONS</li>
                <li>AWESOME TOURNAMENTS</li>
                <li>THRILLING EVENTS</li>
                <li>FABULOUS CULINARY OPTIONS</li>
                <li>AMAZING GIFTS</li>
                <li>INCREDIBLE FOOD</li>
                <li>SPECTACULAR CUISINE</li>
            </ul>
        </div>
    );
}

export default Scroller;
