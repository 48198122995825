// Chat.js
import React, { useState } from "react";
import chatPicture from "./images/chat_48dp_FILL0_wght400_GRAD0_opsz48 (1).png";
import ZohoWebForm from "./ZohoWebForm";

function Chat() {
    const [formOpen, setFormOpen] = useState(false);

    function openForm() {
        setFormOpen(true);
    }

    return (
        <>
          <div className="chat">
            <div className="chat__text-container">
                <p className="chat__text">Are you new? We have a promotion for you!</p>
            </div>
            <div className="chat__container">
                <img onClick={openForm} src={chatPicture} alt="" className="chat__picture" />
            </div>
        
        </div>
        <ZohoWebForm isOpen={formOpen} toggle={() => setFormOpen(false)} />
        </>
      
    );
}

export default Chat;
