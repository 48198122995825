import React from "react";

import tournamenteimage1 from "./images/tuesday.jpg"
import tournamentimage2 from "./images/wednesday.jpg"
import tournamentimage3 from "./images/saturday.jpg"
import tournamentimage4 from "./images/friday.jpg"




// import secondImage from "./images/secondImage.jpg"; // Import your second image here if needed

function Tournaments() {

    const promotionsData = [
        {
            id:1,
            month:"Enero",
            day:15,
            year:2024,
            image:tournamenteimage1,
            text:"texto",
            when:"Wednesday at 12:00 PM",
            body:"Champions Club 12:00pm $75 NL Holdem - 2 MIL GTD MAIN EVENT STEP 1 MILESTONE SATELLITE",
            cost:"BUY-IN: $75 | CHIPS/LEVELS: 20K/15 MIN | GUARANTEE: 1 Seat to any Step 2 Satellite"
        },
        {
            id:2,
            month:"Enero",
            day:15,
            year:2024,
            image:tournamentimage2,
            text:"texto",
            when:"Wednesday at 12:00 PM",
            body:"Champions Club 12:00pm $75 NL Holdem - 2 MIL GTD MAIN EVENT STEP 1 MILESTONE SATELLITE",
            cost:"BUY-IN: $75 | CHIPS/LEVELS: 20K/15 MIN | GUARANTEE: 1 Seat to any Step 2 Satellite"
        },      {
            id:3,
            month:"Enero",
            day:15,
            year:2024,
            image:tournamentimage3,
            text:"texto",
            when:"Wednesday at 12:00 PM",
            body:"Champions Club 12:00pm $75 NL Holdem - 2 MIL GTD MAIN EVENT STEP 1 MILESTONE SATELLITE",
            cost:"BUY-IN: $75 | CHIPS/LEVELS: 20K/15 MIN | GUARANTEE: 1 Seat to any Step 2 Satellite"
        },
        {
            id:4,
            month:"Enero",
            day:15,
            year:2024,
            image:tournamentimage4,
            text:"texto",
            when:"Wednesday at 12:00 PM",
            body:"Champions Club 12:00pm $75 NL Holdem - 2 MIL GTD MAIN EVENT STEP 1 MILESTONE SATELLITE",
            cost:"BUY-IN: $75 | CHIPS/LEVELS: 20K/15 MIN | GUARANTEE: 1 Seat to any Step 2 Satellite"
        },
        {
            id:5,
            month:"Enero",
            day:15,
            year:2024,
            image:tournamentimage4,
            text:"texto",
            when:"Wednesday at 12:00 PM",
            body:"Champions Club 12:00pm $75 NL Holdem - 2 MIL GTD MAIN EVENT STEP 1 MILESTONE SATELLITE",
            cost:"BUY-IN: $75 | CHIPS/LEVELS: 20K/15 MIN | GUARANTEE: 1 Seat to any Step 2 Satellite"
        },
        {
            id:6,
            month:"Enero",
            day:15,
            year:2024,
            image:tournamentimage4,
            text:"texto",
            when:"Wednesday at 12:00 PM",
            body:"Champions Club 12:00pm $75 NL Holdem - 2 MIL GTD MAIN EVENT STEP 1 MILESTONE SATELLITE",
            cost:"BUY-IN: $75 | CHIPS/LEVELS: 20K/15 MIN | GUARANTEE: 1 Seat to any Step 2 Satellite"
        },
        {
            id:7,
            month:"Enero",
            day:15,
            year:2024,
            image:tournamentimage4,
            text:"texto",
            when:"Wednesday at 12:00 PM",
            body:"Champions Club 12:00pm $75 NL Holdem - 2 MIL GTD MAIN EVENT STEP 1 MILESTONE SATELLITE",
            cost:"BUY-IN: $75 | CHIPS/LEVELS: 20K/15 MIN | GUARANTEE: 1 Seat to any Step 2 Satellite"
        }
        
        
        
    ]
   
    return (
        <div className="tournaments reveal" id="tournaments">

            <div className="tournaments__text-container">
                <h4 className="tournaments__title">TOURNAMENTS AND EVENTS.</h4>
                <p className="tournaments__subtitle">Experience the thrill of entertainment and competition in the heart of San Antonio, enter a world where entertainment knows no bounds, and unforgettable memories are waiting to be made</p>

            </div>
          <div className="tournaments__container">
            {promotionsData.map(promotion =>(
     <div key={promotion.id} className="tournaments__promotions__container">
     <div className="tournaments__promotions-date">
         <p className="tournaments__promotions-date-month">{promotion.month}</p>
         <p className="tournaments__promotions-date-day">{promotion.day}</p>
         <p className="tournaments__promotions-date-year">{promotion.year}</p>
     </div>
     <img src={promotion.image} alt="" className="tournaments__promotions-image" />
     <div className="tournaments__promotions-text">
         <p className="tournaments__promotions-text-when">{promotion.when}</p>
         <p className="tournaments__promotions-text-body">{promotion.body}</p>
         <p className="tournaments__promotions-text-cost">{promotion.cost}</p>
     </div>
 </div>
            ))}
       
     
   
      


          </div>

        </div>
    );
}

export default Tournaments;
